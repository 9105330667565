<template>
    <section id="news-list" class="container my-5">
        <div class="row">
            <div class="col-md-3">
                <div class="nav flex-column nav-pills mb-5" role="tablist">
                    <a class="nav-link" data-toggle="pill" role="tab" 
                        v-for="(v, i) in news" 
                        :href="'#tabpanel-' + i"
                        :class="activeClass(i)" 
                        :key="v.title">
                        {{ v.title }}
                    </a>
                </div>
            </div>
            <div class="col-md-9">
                <div class="tab-content">
                    <div class="tab-pane" role="tabpanel" 
                        v-for="(v, i) in news" 
                        :id="'tabpanel-' + i" 
                        :class="activeClass(i)"
                        :key="v.title">
                        <h3 class="mb-5">{{ v.title }}</h3>
                        <div class="news-card-group container-fluid" v-if="v.data.length">
                            <section class="news-card row mb-5 py-3" 
                                v-for="s in v.data" 
                                :key="s.content" 
                                @click="onDetail(s.content)">
                                <div class="col-4">
                                    <img class="cover img-fluid shadow rounded" :src="require('@a/' + s.cover)" alt="封面图片">
                                </div>
                                <div class="col-8">
                                    <article class="preview mb-3" v-html="getPreview(s.content)"></article>
                                    <small class="text-muted">{{ s.footnote }}</small>
                                </div>
                            </section>
                        </div>
                        <h6 v-else class="text-muted text-center">暂无内容</h6>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import config from '@a/news/config';

export default {
    name: 'List',
    data() {
        return Object.assign({}, config);
    },
    methods: {
        onDetail(path) {
            this.$router.push({
                name: 'news-detail',
                query: { path }
            });
        },
        getPreview(path) {
            const html = require('@a/' + path);
            let result =
                this.$jq(html)
                    .filter('*')
                    .slice(0, 2)
                    .text()
                    .trim()
                    .slice(0, 150) + '……';

            return result;
        },
        activeClass(i) {
            if (this.activeTab === i) return 'active';
        }
    }
};
</script>

<style lang="less">
@import '../../style/mixin';

#news-list {
    .news-card-group {
        .news-card {
            cursor: pointer;
            transition: background-color 0.3s;
            &:hover {
                background-color: var(--light);
            }
        }

        .preview {
            position: relative;

            > :first-child {
                font-weight: bold;
                font-size: 1.2rem;
                margin-bottom: 1rem;
                transition: color 0.3s;
            }

            > :nth-child(2) {
                margin-bottom: 0;
            }
        }
    }
}
</style>